<template>

	<el-dialog title="绩效明细" v-model="membersinfo" width="75%" center append-to-body @close='closeDialog'>

<div class="el-dialog-div">
		<el-form ref="searchForm" label-width="240" :inline="true">

			<el-form-item label="时间查询:" prop="datatime">
				<el-date-picker v-model="beginDate" type="date" value-format="YYYY-MM-DD"></el-date-picker>
				<el-date-picker v-model="endDate" type="date" value-format="YYYY-MM-DD"></el-date-picker>
			</el-form-item>
			<el-form-item label="综合查询:" prop="name">
				<el-input v-model="tableDataName" placeholder="请输入名称" style="width:240px" clearable></el-input>
			</el-form-item>

			<el-form-item>
				<el-button type="primary" @click="queryMembers()">搜索</el-button>
			</el-form-item>
		</el-form>

		<el-table :data="dataList" border ref="multipleTable" height="600">
			<el-table-column prop="id" label="ID" width="100px" align="center"></el-table-column>
			<el-table-column prop="real_name" label="用户名称" width="150px" align="center"></el-table-column>
			<el-table-column prop="user_imgs" label="用户头像" width="150px" align="center">
				<template width="60" v-slot="colum" style="width:55px">
					<el-image style="width:50px;height:50px;border:none; border-radius:70%"
						:src="colum.row.user_imgs" />
				</template>
			</el-table-column>
			<el-table-column prop="classification" label="绩效分类" width="180px" align="center"></el-table-column>
			<el-table-column prop="achievementsName" label="绩效名称" width="400px" align="center"></el-table-column>
			<el-table-column prop="money" label="绩效金额" width="100px" align="center"></el-table-column>
			<el-table-column prop="multiple" label="倍数" width="100px" align="center"></el-table-column>
			<el-table-column prop="create_time" label="评定时间" width="300px" align="center"></el-table-column>
		</el-table>
		</div>
		<el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pagesize"
            layout="total,sizes,prev,pager,next,jmper"
            :total="totalCount">
      </el-pagination>
	  <el-form-item>
	  	<el-button type="primary" @click="closeDialog()" Style="float:right">关闭</el-button>
	  </el-form-item>
	</el-dialog>

</template>

<script>
	export default {
		created() {
			this.membersinfo = true;
			this.handleClickQuery();
		},

		data() {
			let self = this;
			return {
				dataList: [], //表格数据集
				membersDialogVisible: false,
				tableDataName: '', //综合查询条件参数
				optionsype: '', //所属项目选项
				id: this.$parent.id,
				plot_id:this.$parent.plot_id,
				beginDate: '',
				endDate: '',
				totalCount:0,          //总页数
				pagesize:10,        //每页数量
				currentPage:1,    //初始页

			}
		},

		methods: {

			handleClickQuery: function(data) {
				this.dataList = [];
				var req = {};
				req.id = this.id;
				req.plot_id = this.plot_id;
				req.cmd = "searchAchievementsList";
				req.start = (this.currentPage - 1) * this.pagesize;
				req.count = this.pagesize;
				this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
					console.log(res);
					this.dataList = res.datas.items;
					this.totalCount = res.datas.totalCount;
				}, null, this);
			},
			//条件查询的方法
			queryMembers: function() {
				this.dataList = [];
				var req = {};
				req.cmd = "searchAchievementsList";
				req.id = this.id;
				req.classification = this.tableDataName;
				req.achievementsName = this.tableDataName;
				req.real_name = this.tableDataName
				req.beginDate = this.beginDate;
				req.endDate = this.endDate;
				req.plot_id = this.plot_id;
				req.start = (this.currentPage - 1) * this.pagesize;
				req.count = this.pagesize;
				this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
					console.log(res);
					this.dataList = res.datas.items;
				}, null, this);
			},
			closeDialog: function(){
			  this.members = false;
			  // this.clearinfo();
			  this.$emit('closeBonusInfo'); //通知父组件改变。
			},
			handleSizeChange: function (size) {
			  this.pagesize = size;
			  console.log(this.pagesize)  //每页下拉显示数据
			},
		   handleCurrentChange: function(currentPage){
			  this.currentPage = currentPage;
			  this.handleClickQuery();
			  console.log(this.currentPage)  //点击第几页
			},
		},


		mounted() {

		},
		components: {

		}
	}
</script>

<style scoped>
.el-dialog-div {
  height: 60vh;
  overflow: auto;
  Margin:5px;
}
</style>
