	<!--会员管理数据列表-->
<template>
  <div>
  <el-form ref="searchForm"   label-width="240" :inline="true">
    <el-form-item >
		<el-button type="primary" plain  @click="exportExcel()">导出人员奖金统计</el-button>
		<el-button type="warning" plain  @click="exportDetails()">导出人员奖金明细</el-button>
		<el-button type="primary" plain  @click="exportExcelLc()">导出绩效类别统计</el-button>
	</el-form-item>
	<el-form-item label="选择项目:" prop="name">
	  <el-select v-model="article" placeholder="请选择" style="width:240px" clearable>
	    <el-option v-for="data in optionsype" :key="data.plot_id" :label="data.plot_name" :value="data.plot_id"></el-option>
	  </el-select>
	</el-form-item>
    <el-form-item label="综合查询:" prop="name"><el-input v-model="tableDataName" placeholder="请输入名称" style="width:240px" clearable></el-input></el-form-item>
    
    <el-form-item><el-button type="primary" @click="queryMembers()">搜索</el-button></el-form-item>
  </el-form>

  <el-table :data="dataList" border  ref="multipleTable">
    <el-table-column label="序号" >
    	<template v-slot="scope">
    		{{ scope.$index+1 }}
    	</template>
    </el-table-column>
    <el-table-column prop="user_name" label="用户名" ></el-table-column>
    <el-table-column prop="plot_name" label="所属项目"></el-table-column>
    <el-table-column prop="mobile" label="手机号码"></el-table-column>
    <el-table-column prop="user_sex" label="用户性别" :formatter="formatter">
      <template v-slot="scope">
        <el-tag
            :type="scope.row.user_sex==1?'success':scope.row.user_sex==2?'danger':'warning'"
            close-transition>{{scope.row.user_sex==1?'男':scope.row.user_sex==2?'女':'未知'}}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="user_imgs" label="用户头像" >
      <template width="60" v-slot="colum" style="width:55px" >
        <el-image style="width:50px;height:50px;border:none; border-radius:70%" :src="colum.row.user_imgs" />
      </template>
    </el-table-column>
    <el-table-column prop="real_name" label="真实姓名"></el-table-column>
    <el-table-column fixed="right" label="操作" width="100" >
      <template v-slot="scope">
        <el-button  icon="el-icon-edit"    @click="lookUp(scope.row)" type="success" size="small">查看绩效</el-button>
      </template>
    </el-table-column>
  </el-table>
    <el-config-provider :locale="locale">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pagesize"
          layout="total,sizes,prev,pager,next,jmper"
          :total="totalCount">
      </el-pagination>
    </el-config-provider>

    <rms_bonus_info @closeBonusInfo="closeBonusInfo"  v-if="bonusInfoDialogVisible"></rms_bonus_info>
  <rms_achievements_export @closeExport="closeExport" v-if="exportDialogVisible"></rms_achievements_export>
   <rms_achievements_export_total @closeExport="closeExportTotal" v-if="exportTotalDialogVisible"></rms_achievements_export_total>
  <rms_details_export @closeExportDetail="closeExportDetail" v-if="exportDetailDialogVisible"></rms_details_export>
  </div>
</template>
<script>
import rms_bonus_info from './rms_bonus_info.vue'
import rms_achievements_export from './rms_achievements_export.vue'
import rms_achievements_export_total from './rms_achievements_export_total.vue'
import rms_details_export from './rms_details_export.vue'
//引入vue方法
import { ElConfigProvider } from 'element-plus';
//中文包
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {

  name: 'ZhProvider',

  setup(){
    let locale = zhCn
    return{
      locale
    }
  },	data() {
	  let self=this;
	  return {
		ids:[],
	    dataList:[],  //表格数据集
	    bonusInfoDialogVisible: false,
		exportDialogVisible:false,
		exportTotalDialogVisible:false,
		exportDetailDialogVisible:false,
	    tableDataName:'',   //综合查询条件参数
	    optionsype:'',     //所属项目选项
	    article:'',       //选择的所属项目
	    user_sex:[],
	    totalCount:0,          //总页数
	    pagesize:10,        //每页数量
	    currentPage:1,    //初始页
	  }
	},

  /*启动后触发该方法*/
  created() {
    this.handleClickQuery();
    this.getplotname();
  },

  methods: {
    handleClickQuery:function(data){
      this.dataList = [];
      var req = {};
      req.cmd = "searchBonusList";
	  req.synthesize_params=this.tableDataName;
	  req.plot_id =this.article;
      req.start=(this.currentPage-1) * this.pagesize;
      req.count=this.pagesize;
      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
        console.log(res);
        this.dataList=res.datas.items;
        this.totalCount=res.datas.totalCount;
		self.ids = res.datas.ids;
      }, null, this);
    },

    lookUp: function(item) {
      this.id = item.id+"";
	  this.plot_id = this.article;
      this.bonusInfoDialogVisible = true;
    },
    closeBonusInfo:function(){
      this.id = '';
      this.bonusInfoDialogVisible = false;
      // this.handleClickQuery();
    },
	closeExport:function(){
		this.exportDialogVisible = false;
	},
	closeExportDetail:function(){
		this.exportDetailDialogVisible = false;
	},
    //查询所有项目的方法
    getplotname:function(){
      var self = this;
      var redata = {};
      redata.cmd = "getProject";
      // redata.id=this.ids;
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
        this.optionsype=datas.datas.items;
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
    //条件查询的方法
    queryMembers:function(){
        this.dataList = [];
        var req = {};
        req.cmd = "searchBonusList";
        req.synthesize_params=this.tableDataName;
        req.plot_id =this.article;
        req.start=(this.currentPage-1) * this.pagesize;
        req.count=this.pagesize;
        this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
          console.log(res);
          this.dataList=res.datas.items;
          this.totalCount=res.datas.totalCount;
		  self.ids = res.datas.ids;
        }, null, this);
    },
	closeExportTotal:function(){
		this.exportTotalDialogVisible = false;
	},
	exportExcelLc:function(){
		this.exportTotalDialogVisible = true
	},	
    //导出EXCEK的方法
    exportExcel:function(){
		this.exportDialogVisible = true
	 // var req = {};
  //    req.cmd = "exportBonus";
	 // req.synthesize_params=this.tableDataName;
	 // req.plot_id =this.article;
  //    this.$sknet.download(this.$skconf("met.surl"), req)
    },
   exportDetails:function(){
	   this.exportDetailDialogVisible = true
	// var req = {};
 //    req.cmd = "exportAchievements";
	// req.ids = self.ids.join(',');
 //    this.$sknet.download(this.$skconf("met.surl"), req)
   },

    /*分页代码*/
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize)  //每页下拉显示数据
    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      this.handleClickQuery();
      console.log(this.currentPage)  //点击第几页

    },
    //根据返回值判断
    formatter:function(row,colum){
      const sex={
        0:'未知',
        1:'男',
        2:'女',
      }
      return  sex[row.user_sex];
    },


  },


  components: {
    rms_bonus_info,
  	rms_achievements_export,rms_achievements_export_total,
	  rms_details_export,
    [ElConfigProvider.name]: ElConfigProvider,

  },

}


</script>